<template>
    <div class="dashboard">
        <v-container fluid style="padding-top: 0px; padding-bottom: 0px">
            <v-row style="flex-wrap: nowrap">
                <app-sidemenu></app-sidemenu>

                <v-col class="pa-0">
                    <v-row no-gutters>
                        <v-col class="search-bar pa-0 d-flex align-center" cols="12">
                            <app-search></app-search>
                        </v-col>
                        <v-col class="content-div" cols="12">
                            <div class="pa-8" style="height: 100%">
                                <transition name="fade" mode="out-in">
                                    <router-view></router-view>
                                </transition>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <app-alert></app-alert>
        </v-container>
    </div>
</template>

<script>
    const Sidemenu = () => import("./Sidemenu");
    const Search = () => import("./Search");
    const Alert = () => import("../../mycomponents/Alert");
    export default {
        components: {
            AppSidemenu: Sidemenu,
            AppSearch: Search,
            AppAlert: Alert,
        },

        created() {
            if (this.$router.currentRoute.path === "/") {
                this.$router.push({ path: "/home" });
            }
        },

        watch: {
            $route(to, from) {
                if (this.$router.currentRoute.path === "/") {
                    this.$router.push({ path: "/home" });
                }
            },
        },
    };
</script>

<style lang="scss">
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .content-div {
        min-width: 100%;
        max-width: 100%;
        margin-top: -80px;
        margin-bottom: -20px;
        padding-top: 80px !important;
        padding-bottom: 20px;
        min-height: 100vh;
        max-height: 100vh;
        overflow-y: scroll;
        scroll-margin-top: 500px !important;
    }

    .search-bar {
        min-height: 80px !important;
        box-shadow: 0 8px 24px 0 rgba(18, 112, 171, 0.08) !important;
        z-index: 2;
    }
</style>
